import React, { useState, useEffect } from 'react'

import { withTranslation, useTranslation } from 'react-i18next'
import { getCurrentLang } from '../i18n'
import { useParams } from 'react-router-dom'
import { getDestinations, getInfoByCountryAndLang } from '../fetchs'

import InnerMeta from '../components/InnerMeta'

import Hero from './sections/Hero'
import Download from './sections/Download'
import Benefits from './sections/Benefits'
import Steps from './sections/Steps'
import FAQ from './sections/FAQ'
import SendMoneyWithSenda from './sections/SendMoneyWithSenda'
import SendMoneyTo from './sections/SendMoneyTo'
import BankSection from './sections/BankSection'
const LandingSenda = () => {
  const { t, i18n } = useTranslation()
  const { routeFlag } = useParams()
  const [activeFlag, setActiveFlag] = useState(routeFlag?.toUpperCase() || 'CU')
  const [countries, setCountries] = useState(null)

  const [mainImage, setMainImage] = useState('')
  const [downloadImage, setDownloadImage] = useState('')
  const [stepsImage, setStepsImage] = useState('')
  const [banks, setBanks] = useState([])
  const activeCountry = (className = null) => {
    const country = countries?.find(
      country => country.slug === routeFlag.toUpperCase()
    )
    if (className) {
      return `<span class=${className}>${country ? country.name : ''}</span>`
    } else {
      return country ? country.name : ''
    }
  }
  useEffect(() => {
    getinfo(routeFlag?.toUpperCase() || 'CU')
    async function fetchData() {
      try {
        const response = await getDestinations()
        const data = await response.json()
        setCountries(data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    getinfo(routeFlag)
  }, [routeFlag])

  const getinfo = country => {
    async function fetchData() {
      try {
        const response = await getInfoByCountryAndLang(country, i18n.language)
        const data = await response.json()
        setMainImage(
          data.mainImage
            ? data.mainImage
            : 'https://umedia.tropipay.com/senda/landing/Hero_Default_Senda.jpg'
        )
        setDownloadImage(
          data.section1Image
            ? data.section1Image
            : 'https://umedia.tropipay.com/senda/landing/Group_Default_Senda.jpg'
        )
        setStepsImage(
          `https://umedia.tropipay.com/senda/landing/calc_${i18n.language}.jpg`
        )
        setBanks(data.landingBanks)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }

  return (
    <>
      <InnerMeta
        lang={getCurrentLang()}
        keywords={t('meta.keywords')}
        description={t('meta.description')}
        canonical={'https://www.sendaapp.com'}
        image={
          'https://www.sendaapp.com/static/media/SendaLogo.67f84baf1a9fbe04b97b4e1231e99151.svg'
        }
      />
      <div className='landing-senda'>
        <Hero
          activeCountry={activeCountry}
          activeFlag={activeFlag}
          setActiveFlag={setActiveFlag}
          image={mainImage}
        />
        <BankSection banks={banks} />
        <Download image={downloadImage} />
        <Benefits />
        <SendMoneyWithSenda activeCountry={activeCountry} />
        <Steps image={stepsImage} />
        <SendMoneyTo />
        <FAQ activeCountry={activeCountry} />
      </div>
    </>
  )
}

export default withTranslation()(LandingSenda)
