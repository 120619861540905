import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from './locales/en.json'
import translationES from './locales/es.json'
import translationPT from './locales/pt.json'

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  pt: {
    translation: translationPT
  }
}

const getUserLang = () => {
  const defaultLanguage = 'es'
  const navigatorLang = window.navigator.language
  const supported = Object.keys(resources).find(lang =>
    navigatorLang.includes(lang)
  )
  return supported || defaultLanguage
}

i18n.use(initReactI18next).init({
  resources,
  lng: getUserLang(),
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false
  }
})

export const getCurrentLang = () => {
  return i18n.language
}

export default i18n
