import React from 'react'

import { withTranslation, useTranslation } from 'react-i18next'

const Steps = props => {
  const { t } = useTranslation()

  const StepByStep = [
    {
      title: t('LandingSenda.StepByStep.0.Title'),
      text: t('LandingSenda.StepByStep.0.Text')
    },
    {
      title: t('LandingSenda.StepByStep.1.Title'),
      text: t('LandingSenda.StepByStep.1.Text')
    },
    {
      title: t('LandingSenda.StepByStep.2.Title'),
      text: t('LandingSenda.StepByStep.2.Text')
    },
    {
      title: t('LandingSenda.StepByStep.3.Title'),
      text: t('LandingSenda.StepByStep.3.Text')
    }
  ]

  return (
    <section className='step-by-step'>
      <div className='container custom-container'>
        <div className='onMobile'>
          <div className='group-phones text-center'>
            <img
              src={props.image}
              alt='Imagen ilustrativa'
              style={{ width: '80%', maxWidth: '310px' }}
            />
          </div>
        </div>

        <h2 className='onDesktop'>{t('LandingSenda.StepByStepTitle')}</h2>
        <div className='step-wrapper'>
          <div className='step-left'>
            <h2 className='onMobile'>{t('LandingSenda.StepByStepTitle')}</h2>
            <ul>
              {StepByStep.map((step, index) => (
                <li key={index}>
                  <p>{step.title}</p>
                  <p>{step.text}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className='step-right onDesktop'>
            <div className='text-center'>
              <img
                src={props.image}
                alt='Imagen ilustrativa'
                style={{ width: '100%', maxWidth: '310px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(Steps)
