import { Button, Typography } from '@mui/material'
import React from 'react'

import { withTranslation, useTranslation } from 'react-i18next'
import I18Nhtml from '../../components/i18Nhtml'

const SendMoneyWithSenda = props => {
  const { t } = useTranslation()

  return (
    <section className='SendMoneyWithSenda' id='SendMoneyWithSenda'>
      <div className='container custom-container'>
        <div className='SendMoney-wrapper'>
          <h2>
            <I18Nhtml
              translation='LandingSenda.SendMoneyWithSenda.title'
              country={props.activeCountry('greenColor')}
            />
          </h2>
          <Typography>
            {t('LandingSenda.SendMoneyWithSenda.subtitle')}{' '}
          </Typography>
          <Button
            color='primary'
            className='sendNow'
            onClick={() => {
              window.scrollTo({
                top: document.querySelector(`#download`).offsetTop,
                behavior: 'smooth'
              })
            }}
          >
            {t('LandingSenda.SendMoneyWithSenda.buttonText')}{' '}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(SendMoneyWithSenda)
