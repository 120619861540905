import flagAR from '../images/flags/AR.png'
import flagBO from '../images/flags/BO.png'
import flagCO from '../images/flags/CO.png'
import flagCU from '../images/flags/CU.png'
import flagDO from '../images/flags/DO.png'
import flagPE from '../images/flags/PE.png'
import flagVE from '../images/flags/VE.png'
import flagBR from '../images/flags/BR.png'
import flagNG from '../images/flags/NG.png'
import flagGM from '../images/flags/GM.png'
import flagHN from '../images/flags/HN.png'
import flagPY from '../images/flags/PY.png'

import MasterCardLogo from '../images/payments/mastercard.svg'
import VisaLogo from '../images/payments/visa.svg'
import DiscoverLogo from '../images/payments/discover.svg'

export const countriesIMG = [
  {
    slug: 'CU', // cuba
    img: flagCU
  },
  {
    slug: 'DO', // rep. dominicana
    img: flagDO
  },
  {
    slug: 'VE', // venezuela
    img: flagVE
  },
  {
    slug: 'AR', // argentina
    img: flagAR
  },
  {
    slug: 'BO', // bolivia
    img: flagBO
  },
  {
    slug: 'CO', // colombia
    img: flagCO
  },
  {
    slug: 'PE', // peru
    img: flagPE
  },
  {
    slug: 'BR', // brazil
    img: flagBR
  },
  {
    slug: 'GM', // gambia
    img: flagGM
  },
  {
    slug: 'NG', // nigeria
    img: flagNG
  },
  {
    slug: 'HN', // honduras
    img: flagHN
  },
  {
    slug: 'PY', // paraguay
    img: flagPY
  }
]

export const paymentsMethods = [
  {
    slug: 'mc', // MasterCard
    img: MasterCardLogo
  },
  {
    slug: 'vi', // Visa
    img: VisaLogo
  },
  {
    slug: 'di', // Discover
    img: DiscoverLogo
  }
]

export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
export const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
export const AppStoreLink =
  'https://apps.apple.com/us/app/senda-pay/id6450757261'
export const PlayStoreLink =
  'https://play.google.com/store/apps/details?id=com.senda.app'

module.export = {
  isMobile,
  isIOS,
  AppStoreLink,
  PlayStoreLink,
  countriesIMG,
  paymentsMethods
}
