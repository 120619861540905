import React, { useState, useEffect } from 'react'

import { withTranslation } from 'react-i18next'

import {
  Typography,
  MenuItem,
  Select,
  Button,
  Grid,
  InputAdornment,
  Input
} from '@mui/material'

import { makeStyles, withStyles } from '@mui/styles'

import { isMobile, isIOS, AppStoreLink, PlayStoreLink } from '../plugins/utils'
import { getDestinations, getPrices } from '../fetchs'

const useStyles = makeStyles(theme => ({
  grid: {
    padding: '18px !important',
    background: '#F8F9FA !important',
    border: '1px solid rgba(52, 58, 64, 0.15) !important',
    borderRadius: '16px !important',
    justifyContent: 'space-around !important'
  },
  '@media (min-width: 460px)': {
    grid: {
      width: '351px !important'
    }
  },

  inputContainer: {
    background: '#F7F5FD !important',
    border: '0.5px solid rgba(52, 58, 64, 0.15) !important',
    borderRadius: '10px !important',
    padding: '8px 16px !important',
    height: '44px !important',
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'space-between !important',
    '&:hover': {
      background: ' #ffffff !important',
      transition: 'background 300ms !important'
    }
  },
  button: {
    background: ' #7C34E7 !important',
    borderRadius: '10px !important'
  },
  titles: {
    fontFamily: 'Lato !important',
    fontStyle: 'normal !important',
    fontWeight: 400,
    fontSize: '12px !important',
    lineHeight: '16px !important',
    letterSpacing: '0.004em !important',
    color: '#343A40 !important',
    marginBottom: '5px !important'
  },
  description: {
    background: '#FFFFFF !important',
    borderRadius: '10px !important'
  },
  descriptionText: {
    fontWeight: 500,
    fontSize: '14px !important',
    color: '#343A40 !important'
  },
  lastText: {
    fontWeight: 700,
    fontSize: '16px !important',
    color: '#7C34E7 !important'
  },
  countryItem: {
    display: 'flex !important',
    alignItems: 'center !important'
  }
}))

const CustomInput = withStyles({
  input: {
    padding: 0,
    '-webkit-appearance': 'none !important',
    '-moz-appearance': 'textfield !important',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      display: 'none !important'
    },
    '&:focus': {
      outline: 'none !important'
    }
  }
})(Input)

const CalculatorTotalSenda = props => {
  const { t, countriesIMG, activeFlag, setActiveFlag } = props

  const styles = useStyles()

  const [country, setCountry] = useState(activeFlag)
  const [amountSent, setAmountSent] = useState('100')
  const [currencySent, setCurrencySent] = useState('EUR')
  const [amountReceived, setAmountReceived] = useState('')
  const [currencyReceived, setCurrencyReceived] = useState('MLC')
  const [feeExchange, setFeeExchange] = useState(0)
  const [rate, setRate] = useState(null)
  const [totalPay, setTotalPay] = useState(0)
  const [countriesList, setCountriesList] = useState()
  const [currentInput, setCurrentInput] = useState(null)

  const [responseApi, setResponseApi] = useState()

  const [formData, setFormData] = useState({
    toSend: amountSent * 100,
    currency: currencySent,
    country
  })

  const [selectedCountry, setSelectedCountry] = useState(undefined)

  // Countries list api
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDestinations()
        const data = await response.json()
        setCountriesList(data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  // Prices api
  useEffect(() => {
    // wait 1 sec to call api when user write
    const timeoutId = setTimeout(async () => {
      // avoid call endpoint when mount
      if (formData.toSend !== '') {
        try {
          const response = await getPrices(formData)
          const data = await response.json()
          setResponseApi(data.prices[0])
        } catch (error) {
          console.error(error)
        }
      }
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [formData])

  useEffect(() => {
    if (responseApi !== undefined) {
      if (currentInput === 1) {
        setAmountReceived(responseApi.toGet / 100)

        setFeeExchange(responseApi.fee)
        setRate(responseApi.rate)
        setTotalPay(responseApi.toPay)
      } else if (currentInput === 2) {
        setAmountSent(responseApi.toSend / 100)
        setRate(responseApi.rate)

        setFeeExchange(responseApi.fee)
        setTotalPay(responseApi.toPay)
      }
    }
  }, [responseApi, currentInput])

  useEffect(() => {
    if (currentInput === 1) {
      setFormData(() => ({
        toSend: parseInt(amountSent * 100),
        currency: currencySent,
        country
      }))
    }
  }, [currencySent, country, amountSent, currentInput])

  useEffect(() => {
    if (currentInput === 2) {
      setFormData(() => ({
        toGet: parseInt(amountReceived * 100),
        currency: currencySent,
        country,
        destinationCurrency: currencyReceived
      }))
    }
  }, [currencySent, country, amountReceived, currencyReceived, currentInput])

  useEffect(() => {
    // se utiliza para setear la currency cuando se cambia el pais para un toGet
    setCurrencyReceived(selectedCountry?.remittanceCurrencies[0])
  }, [country, selectedCountry])

  useEffect(() => {
    if (countriesList !== undefined) {
      setSelectedCountry(countriesList.find(pais => pais.slug === country))
    }
  }, [countriesList, country])

  useEffect(() => {
    // Set country when user click on landing destinations
    setCountry(activeFlag)
  }, [activeFlag])

  useEffect(() => {
    // Set active country when user click country in calculator
    setActiveFlag(country)
  }, [country])

  useEffect(() => {
    if (responseApi && amountReceived === '') {
      // Calculate the default value for amountReceived when mount
      const defaultAmountReceived = responseApi.toGet / 100

      // Set values
      setAmountReceived(defaultAmountReceived)
      setFeeExchange(responseApi.fee)
      setRate(responseApi.rate)
      setTotalPay(responseApi.toPay)
    }
  }, [responseApi])

  const validateInput = value => {
    const regex = /^[0-9]+(\.[0-9]{1,2})?$/
    return value.trim() === '' || regex.test(value)
  }

  const handleSection = id => {
    if (isMobile) {
      if (isIOS) {
        window.location.href = AppStoreLink
      } else {
        window.location.href = PlayStoreLink
      }
    } else {
      window.scrollTo({
        top: document.querySelector(`#${id}`).offsetTop,
        behavior: 'smooth'
      })
    }
  }

  function handleCountryChange(event) {
    setCountry(event.target.value)
    setCurrentInput(1)
  }

  function handleAmountSentChange(event) {
    if (validateInput(event.target.value)) {
      setAmountSent(event.target.value)
    }

    setCurrentInput(1)
  }

  function handleCurrencySentChange(event) {
    setCurrencySent(event.target.value)
  }

  function handleAmountReceivedChange(event) {
    if (validateInput(event.target.value)) {
      setAmountReceived(event.target.value)
    }
    setCurrentInput(2)
  }

  function handleCurrencyReceivedChange(event) {
    setCurrencyReceived(event.target.value)
  }

  return (
    <form className='calcualtor-container'>
      <Grid
        container
        direction='column'
        className={`${styles.grid} disable-underline`}
      >
        <Grid>
          <Typography variant='body2' className={styles.titles}>
            {t('LandingSenda.Calculator.DestinationCountry')}
          </Typography>

          <Select
            value={selectedCountry ? selectedCountry.slug : ''}
            onChange={handleCountryChange}
            fullWidth
            className={`${styles.inputContainer}`}
            variant='standard'
          >
            {countriesList &&
              countriesList.map(country => {
                // search flag image in countryImg array (local), comparing countries slugs
                const countryImg = countriesIMG.find(
                  img => img.slug === country.slug
                )

                return (
                  <MenuItem key={country.slug} value={country.slug}>
                    <div className={styles.countryItem}>
                      <img
                        src={
                          countryImg
                            ? countryImg.img
                            : `https://flagsapi.com/${country.slug}/flat/32.png`
                        }
                        width='28px'
                        className='d-inline-block'
                        alt={`${country.name} flag`}
                      />
                      <Typography className='ml-2' style={{ color: '#343A40' }}>
                        {country.name}
                      </Typography>
                    </div>
                  </MenuItem>
                )
              })}
          </Select>
        </Grid>
        <Grid>
          <Typography variant='body2' className={`${styles.titles} mt-3`}>
            {t('LandingSenda.Calculator.YouSend')}
          </Typography>

          <CustomInput
            type='number'
            value={amountSent}
            onChange={handleAmountSentChange}
            className={`${styles.inputContainer}`}
            inputProps={{ min: '0' }}
            fullWidth
            placeholder='0'
            endAdornment={
              <InputAdornment position='end'>
                <Select
                  value={currencySent}
                  onChange={handleCurrencySentChange}
                  className='vertical-line'
                  variant='standard'
                >
                  <MenuItem value='EUR'>EUR</MenuItem>
                  <MenuItem value='USD'>USD</MenuItem>
                </Select>
              </InputAdornment>
            }
          />
        </Grid>

        <div
          className={`container p-2 pl-3 pr-3 mb-2 mt-3 ${styles.description} ${styles.descriptionText}`}
        >
          <div className='d-flex'>
            <div className='mr-auto'>
              <p className='mb-1 mt-1'>
                {t('LandingSenda.Calculator.ExchangeRate')}
              </p>
              <p className='mb-1'>{t('LandingSenda.Calculator.PaymentFee')}</p>
              <p className={`${styles.lastText} mb-1`}>
                {t('LandingSenda.Calculator.TotalPay')}
              </p>
            </div>
            <div className='ml-auto'>
              <p className='mb-1 mt-1'>{rate === null ? <></> : rate}</p>
              <p className='mb-1 mt-1'>
                {feeExchange === 0 ? (
                  <></>
                ) : (
                  `${feeExchange / 100} ${currencySent}`
                )}
              </p>
              <p className={`${styles.lastText} mb-1`}>
                {totalPay === 0 ? <></> : `${totalPay / 100} ${currencySent}`}
              </p>
            </div>
          </div>
        </div>

        <Grid>
          <Typography variant='body2' className={`${styles.titles} mt-3`}>
            {t('LandingSenda.Calculator.BeneficiaryGet')}
          </Typography>
          <div>
            <CustomInput
              type='number'
              value={amountReceived}
              onChange={handleAmountReceivedChange}
              className={`${styles.inputContainer}`}
              inputProps={{ min: '0' }}
              fullWidth
              placeholder='0'
              endAdornment={
                <InputAdornment position='end'>
                  <Select
                    value={currencyReceived || ''}
                    onChange={handleCurrencyReceivedChange}
                    className='vertical-line'
                    variant='standard'
                  >
                    <MenuItem value={currencyReceived}>
                      {currencyReceived}
                    </MenuItem>
                  </Select>
                </InputAdornment>
              }
            />
          </div>
        </Grid>
        <Button
          variant='contained'
          size='large'
          color='primary'
          type='submit'
          className={`${styles.button} mt-3`}
          onClick={e => {
            e.preventDefault()
            handleSection('download')
          }}
        >
          {t('LandingSenda.Calculator.BtnSend')}
        </Button>
      </Grid>
    </form>
  )
}

export default withTranslation()(CalculatorTotalSenda)
