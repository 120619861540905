import React from 'react'

import { withTranslation, useTranslation } from 'react-i18next'

import {
  isMobile,
  isIOS,
  AppStoreLink,
  PlayStoreLink
} from '../../plugins/utils'

import SendaLogo from '../../images/SendaLogo.svg'
import AppStore from '../../images/icons/AppStore.svg'
import PlayStore from '../../images/icons/PlayStore.svg'

import { Button, Typography } from '@mui/material'

const Download = props => {
  const { t } = useTranslation()

  return (
    <section className='download' id='download'>
      <div className='container custom-container'>
        <div className='download-wrapper'>
          <div className='download-left'>
            <div className='img-container'>
              <img src={props.image} alt='Imagen ilustrativa' />
            </div>
          </div>
          <div className='download-right'>
            <div className='logo-box'>
              <img
                src={SendaLogo}
                alt='Logo de Senda'
                className='logo-senda-app'
              />
              <div className='logo-text'>
                <Typography>{t('LandingSenda.Description.brand')}</Typography>
                <Typography>{t('LandingSenda.Description.by')}</Typography>
              </div>
            </div>

            <div className='download-app'>
              <Typography variant='h2'>
                {t('LandingSenda.Description.title')}
              </Typography>
              <Typography>
                {t('LandingSenda.Description.description')}
              </Typography>
            </div>

            <div className='mobile-stores'>
              {isMobile ? (
                isIOS ? (
                  <a href={AppStoreLink}>
                    <Button className='mobile-appstore' fullWidth>
                      <img src={AppStore} alt='AppStore Download' />
                      <Typography variant='body2'>
                        {t('LandingSenda.PublicMenu.Download')}
                      </Typography>
                    </Button>
                  </a>
                ) : (
                  <a href={PlayStoreLink}>
                    <Button className='mobile-appstore' fullWidth>
                      <img src={PlayStore} alt='AppStore Download' />
                      <Typography variant='body2'>
                        {t('LandingSenda.PublicMenu.Download')}
                      </Typography>
                    </Button>
                  </a>
                )
              ) : (
                <>
                  <a href={PlayStoreLink} target='_blank' rel='noreferrer'>
                    <Button className='mobile-playstore'>
                      <img src={PlayStore} alt='PlayStore Download' />
                      <Typography variant='body2'>
                        {t('LandingSenda.PublicMenu.Download')}
                      </Typography>
                    </Button>
                  </a>
                  <a href={AppStoreLink} target='_blank' rel='noreferrer'>
                    <Button className='mobile-appstore'>
                      <img src={AppStore} alt='AppStore Download' />
                      <Typography variant='body2'>
                        {t('LandingSenda.PublicMenu.Download')}
                      </Typography>
                    </Button>
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(Download)
