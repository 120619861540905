import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import Logo from '../images/SendaLogoMenu.svg'
import { withTranslation, useTranslation } from 'react-i18next'
import { isMobile, isIOS, AppStoreLink, PlayStoreLink } from '../plugins/utils'

import SelectLanguage from './SelectLanguage'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  disabledFocus: {
    outline: 'none !important',
    '&:focus': {
      outline: 'none !important'
    }
  },
  header: {
    // background: 'rgba(255, 255, 255, 0.9) !important',
    backdropFilter: 'blur(8px) !important',
    boxShadow: 'none !important',
    paddingTop: '2px !important',
    paddingBottom: '2px !important'
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    // flexGrow: '1 !important'
  },
  link: {
    color: '#808080 !important',
    background: 'none !important',
    cursor: 'pointer',
    border: 'none !important',
    marginBottom: '0 !important',
    marginRight: '10px !important',
    display: 'inline-block',
    textTransform: 'capitalize !important',
    fontFamily: 'Lato, sans-serif !important',
    fontWeight: '700 !important',
    // height: '36.5px !important',
    '&:focus': {
      outline: 'none !important'
    },
    '&:hover': {
      backgroundColor: '#efefef !important',
      transition: 'cubic-bezier(0.4, 0, 0.2, 1)'
    }
  },

  activeLink: {
    color: '#7C34E7 !important',
    background: 'none !important',
    marginBottom: '0 !important',
    marginRight: '10px !important',
    textTransform: 'capitalize !important',
    fontFamily: 'Lato, sans-serif !important',
    fontWeight: '700 !important',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none !important'
    },
    '&:hover': {
      backgroundColor: '#efefef !important',
      transition: 'cubic-bezier(0.4, 0, 0.2, 1)'
    }
  },
  downloadButton: {
    padding: '6px 18px !important',
    backgroundColor: '#7C34E7 !important',
    borderRadius: '4px !important',
    color: '#FFFFFF !important',
    marginBottom: '0 !important',
    textTransform: 'capitalize !important',
    fontFamily: 'Lato, sans-serif !important',
    fontWeight: '700 !important',
    cursor: 'pointer',
    '&:hover': {
      color: '#7C34E7 !important',
      backgroundColor: '#efefef !important',
      outline: '2px solid #7C34E7 !important'
    }
  },
  drawerMobile: {
    width: '300px',
    padding: '2rem'
  }
}))

const SendaMenu = props => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [openMenu, setOpenMenu] = useState(false)
  const [activeLink, setActiveLink] = useState(
    t('LandingSenda.PublicMenu.Calculator')
  )

  const handleMenu = e => {
    setOpenMenu(!openMenu)
  }

  const handleActiveLink = link => {
    setActiveLink(link)
  }

  const handleSection = id => {
    if (isMobile && id === 'download') {
      if (isIOS) {
        window.location.href = AppStoreLink
      } else {
        window.location.href = PlayStoreLink
      }
    } else if (document.querySelector(`#${id}`)) {
      window.scrollTo({
        top: document.querySelector(`#${id}`).offsetTop,
        behavior: 'smooth'
      })
    } else {
      window.location.href = `/#${id}`
    }
  }

  const MenuList = [
    {
      label: t('LandingSenda.PublicMenu.Calculator'),
      onClick: () => {
        handleActiveLink(t('LandingSenda.PublicMenu.Calculator'))
        handleSection('calculator')
      },
      active: activeLink === 'Calculadora'
    },

    {
      label: t('LandingSenda.PublicMenu.Benefits'),
      onClick: () => {
        handleActiveLink(t('LandingSenda.PublicMenu.Benefits'))
        handleSection('benefits')
      },
      active: activeLink === 'Beneficios'
    },

    {
      key: 'language-selector',
      element: <SelectLanguage />
    },

    {
      label: t('LandingSenda.PublicMenu.Download'),
      onClick: () => {
        handleActiveLink(t('LandingSenda.PublicMenu.Download'))
        handleSection('download')
      },
      active: activeLink === 'Descargar'
    }
  ]

  return (
    <div className={styles.root}>
      <AppBar position='fixed' className={styles.header}>
        <Toolbar className='container justify-content-between'>
          <Link className={styles.title} to='/'>
            <img src={Logo} alt={'Senda Logo'} />
          </Link>
          <div className='d-lg-flex align-items-center d-none'>
            {MenuList.map((menu, index) => {
              const { label, onClick, element, to } = menu
              if (element) {
                return <div key={index}>{element}</div>
              } else {
                return (
                  <Button
                    key={index}
                    className={
                      index === MenuList.length - 1
                        ? styles.downloadButton
                        : activeLink === label
                        ? styles.activeLink
                        : styles.link
                    }
                    href={to}
                    target={to ? '_blank' : undefined}
                    onClick={() => {
                      handleActiveLink(label)
                      onClick()
                    }}
                  >
                    {label}
                  </Button>
                )
              }
            })}
          </div>
          <IconButton
            edge='start'
            className={`${styles.menuButton} d-block d-lg-none ${styles.disabledFocus}`}
            aria-label='menu'
            onClick={() => setOpenMenu(!openMenu)}
          >
            <MenuIcon style={{ color: '#323232' }} />
          </IconButton>
        </Toolbar>
        <Drawer anchor='right' open={openMenu} onClose={handleMenu}>
          <div tabIndex={0} role='button' className={styles.drawerMobile}>
            <div className='d-flex justify-content-between align-items-center w-100 marB2'>
              <Link className='logo-link' to='/'>
                <img src={Logo} alt={'Senda Logo'} style={{ width: '140px' }} />
              </Link>

              <IconButton
                data-test-id='closeButton'
                color='inherit'
                className={`openMenuBtn closeIconBtn mb-0 ${styles.disabledFocus}`}
                onClick={handleMenu}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <List>
              {MenuList.map((menu, index) => {
                const { label, onClick, element, to } = menu
                return (
                  <ListItem key={index}>
                    {element ? (
                      <div key={index}>{element}</div>
                    ) : (
                      <Button
                        className={
                          index === MenuList.length - 1
                            ? styles.downloadButton
                            : activeLink === label
                            ? styles.activeLink
                            : styles.link
                        }
                        href={to}
                        target={to ? '_blank' : undefined}
                        onClick={() => {
                          handleActiveLink(label)
                          onClick()
                          setOpenMenu(false)
                        }}
                      >
                        {label}
                      </Button>
                    )}
                  </ListItem>
                )
              })}
            </List>
          </div>
        </Drawer>
      </AppBar>
    </div>
  )
}

export default withTranslation()(SendaMenu)
