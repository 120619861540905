import React, { useState } from 'react'

import { withTranslation, useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import {
  Typography,
  Collapse,
  List,
  ListItemButton,
  ListItemText
} from '@mui/material'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const BankSection = props => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(-1)

  const groupedBanks = props.banks?.reduce((acc, bank) => {
    const { type, ...rest } = bank
    if (acc[type]) {
      acc[type].push(rest)
    } else {
      acc[type] = [rest]
    }
    return acc
  }, {})
  const keys = props.banks ? Object.keys(groupedBanks) : null

  const labelKeys = {
    BANK_DEPOSIT: 'LandingSenda.BankSection.BANK_DEPOSIT',
    CASH_PICKUP: 'LandingSenda.BankSection.CASH_PICKUP',
    PHONE: 'LandingSenda.BankSection.PHONE',
    WALLET: 'LandingSenda.BankSection.WALLET'
  }

  const listItems = key => {
    return (
      <div className='listBanks' key={key}>
        {groupedBanks[key].map((item, index) => (
          <div key={index} className='bank'>
            <img src={item.logo} alt={item.name} />
          </div>
        ))}
      </div>
    )
  }

  const handleOpen = item => {
    setOpen(item.index === open ? -1 : item.index)
  }

  if (props.banks) {
    return (
      <section className='BankSection-senda'>
        <div className='container custom-container'>
          <Typography align='center' variant='h2'>
            {t('LandingSenda.BankSectionTitle')}
          </Typography>
          <Typography className='pt-3 pb-3' align='center'>
            {t('LandingSenda.BankSectionSubtitle')}
          </Typography>

          <Carousel
            className='onDesktop'
            centerMode={false}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            emulateTouch={true}
            infiniteLoop={false}
            showIndicators={true}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const defStyle = {
                color: '#251947',
                fontFamily: 'Lato',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                letterSpacing: '1.25px',
                textTransform: 'uppercase',
                margin: '0 34px',
                paddingBottom: '20px'
              }
              const style = isSelected
                ? {
                    ...defStyle,
                    borderBottom: '7px solid #251947'
                  }
                : { ...defStyle }
              return (
                <span
                  style={style}
                  onClick={onClickHandler}
                  value={index}
                  key={index}
                  role='button'
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                >
                  {t(labelKeys[keys[index]])}
                </span>
              )
            }}
          >
            {keys.map(key => {
              return listItems(key)
            })}
          </Carousel>

          {keys.map((key, index) => (
            <div key={index} className='onMobile'>
              <ListItemButton onClick={() => handleOpen({ index })}>
                <ListItemText
                  className='titleBankList'
                  primary={t(labelKeys[keys[index]])}
                />
                {open === index ? (
                  <ExpandLess className='change' />
                ) : (
                  <ExpandMore className='change' />
                )}
              </ListItemButton>
              <Collapse in={open === index} unmountOnExit>
                <List component='div' disablePadding>
                  <ListItemButton
                    className='detailBanks'
                    sx={{ pl: { index } }}
                  >
                    <ListItemText primary={listItems(key)} />
                  </ListItemButton>
                </List>
              </Collapse>
            </div>
          ))}
        </div>
      </section>
    )
  } else {
    return null
  }
}

export default withTranslation()(BankSection)
