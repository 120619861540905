import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { withTranslation, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { countriesIMG, paymentsMethods } from '../../plugins/utils'

import PoweredByTropiPay from '../../images/payments/PoweredByTropiPay.svg'

import CalculatorTotalSenda from '../../components/CalculatorTotalSenda'
import I18Nhtml from '../../components/i18Nhtml'
// import CalculatorSenda from '../../components/CalculatorSenda'

const Hero = props => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  useEffect(() => {
    navigate(`/${props.activeFlag.toLowerCase()}`)
  }, [props.activeFlag])

  return (
    <div
      className='containerToHero'
      style={{ backgroundImage: `url(${props.image})` }}
    >
      <section className='hero' id='calculator'>
        <div className='container custom-container'>
          <div className='hero-wrapper'>
            <div className='hero-left'>
              <Typography variant='h1' className='title'>
                <I18Nhtml
                  translation='LandingSenda.Hero.title'
                  country={props.activeCountry('greenColor')}
                />
              </Typography>

              <p className='hero-subtitle mt-3'>
                {t('LandingSenda.Hero.subtitle')}
              </p>

              {/* <div className='countries-selection pt-3 pb-3 onDesktop'>
          <div className='text-left'>
            <CountriesInHero
              setActiveFlag={setActiveFlag}
              activeFlag={activeFlag}
              countriesIMG={countriesIMG}
            />
          </div>
        </div> */}
              <div className='payment pt-4 onDesktop'>
                <div className='text-left'>
                  {paymentsMethods.map((payment, index) => (
                    <span key={index} className='pr-1 mr-1'>
                      <img alt='Payments Methods' src={payment.img} />
                    </span>
                  ))}
                  <img
                    className='onDesktop'
                    src={PoweredByTropiPay}
                    style={{ width: '166px' }}
                    alt='Powered by TropiPay'
                  />
                </div>
              </div>
            </div>
            <div className='hero-right'>
              <div className='calculator'>
                <CalculatorTotalSenda
                  countriesIMG={countriesIMG}
                  activeFlag={props.activeFlag}
                  setActiveFlag={props.setActiveFlag}
                />
                {/* <CalculatorSenda
                countriesIMG={countriesIMG}
                activeFlag={activeFlag}
                setActiveFlag={setActiveFlag}
              /> */}

                <div className='payment pt-1 onMobile'>
                  <div className='calculatorIcons'>
                    {paymentsMethods.map((payment, index) => (
                      <img
                        key={index}
                        alt='Payments Methods'
                        src={payment.img}
                      />
                    ))}
                    <img
                      className='onMobile tpp'
                      src={PoweredByTropiPay}
                      alt='Powered by TropiPay'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withTranslation()(Hero)
