import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { withTranslation, Trans, useTranslation } from 'react-i18next'

const AmlKyc = props => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <div className='container mt-5 pt-5 mb-5 pb-5 terms'>
      <Typography variant='h1' className='mb-5'>
        {t('kyc.title')}
      </Typography>

      <Typography className='mb-3'>{t('kyc.description')}</Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('kyc.subtitle1')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          components={{
            ul: <ul />,
            li: <li />
          }}
          i18nKey={t('kyc.description1')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('kyc.subtitle2')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('kyc.description2')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('kyc.subtitle3')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('kyc.description3')}
          components={{
            ul: <ul />,
            li: <li />
          }}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('kyc.subtitle4')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('kyc.description4')}
          components={{
            ul: <ul />,
            li: <li />
          }}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('kyc.subtitle5')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('kyc.description5')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('kyc.subtitle6')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('kyc.description6')}
          components={{
            ul: <ul />,
            li: <li />
          }}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('kyc.subtitle7')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('kyc.description7')}
          components={{
            ul: <ul />,
            li: <li />
          }}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>
    </div>
  )
}

export default withTranslation()(AmlKyc)
