import React from 'react'
import { withTranslation, useTranslation } from 'react-i18next'
import flagAR from '../../images/flags/AR.png'
import flagMore from '../../images/flags/more.png'
import flagBO from '../../images/flags/BO.png'
import flagCO from '../../images/flags/CO.png'
import flagCU from '../../images/flags/CU.png'
import flagDO from '../../images/flags/DO.png'
import flagPE from '../../images/flags/PE.png'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const SendMoneyTo = () => {
  const { t } = useTranslation()

  const CountryDetail = (slug, icon) => {
    const { t } = useTranslation()
    return (
      <Link to={`/${slug}`}>
        <div className='SendMoneyTo-box'>
          <img
            src={icon}
            className='SendMoneyTo-flagIcon'
            alt={t(`Countries.${slug}`)}
          />
          <Typography>{t(`Countries.${slug}`)}</Typography>
        </div>
      </Link>
    )
  }
  return (
    <section className='SendMoneyTo' id='SendMoneyTo'>
      <div className='container custom-container'>
        <div className='SendMoney-wrapper'>
          <h2>{t('LandingSenda.SendMoneyToTitle')}</h2>
          <div className='SendMoneyTo-container'>
            {CountryDetail('CO', flagCO)}
            {CountryDetail('PE', flagPE)}
            {CountryDetail('BO', flagBO)}
            {CountryDetail('CU', flagCU)}
            {CountryDetail('DO', flagDO)}
            {CountryDetail('AR', flagAR)}

            <div className='SendMoneyTo-box'>
              <img src={flagMore} className='SendMoneyTo-flagIcon' />
              <Typography>{t('LandingSenda.SendMoneyToCountries')}</Typography>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(SendMoneyTo)
