import React from 'react'
import { MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'

import flagES from '../images/flags/ES.png'
import flagUS from '../images/flags/US.png'
import flagPT from '../images/flags/PT.png'

const SelectLanguage = () => {
  const { i18n } = useTranslation()

  const handleChange = event => {
    i18n.changeLanguage(event.target.value)
  }

  return (
    <Select
      key='language-selector'
      labelId='language-selector-label'
      id='language-selector'
      value={i18n.language}
      onChange={handleChange}
      sx={{
        width: 100,
        height: 40,
        marginRight: '10px',
        fontSize: '14px',
        color: '#808080',
        fontWeight: '500',
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '&:hover': {
          backgroundColor: '#efefef !important',
          transition: 'cubic-bezier(0.4, 0, 0.2, 1)'
        }
      }}
    >
      <MenuItem value='es' style={{ fontSize: '14px' }}>
        <img src={flagES} width='24px' className='mr-2' />
        ES
      </MenuItem>
      <MenuItem value='en' style={{ fontSize: '14px' }}>
        <img src={flagUS} width='24px' className='mr-2' />
        EN
      </MenuItem>
      <MenuItem value='pt' style={{ fontSize: '14px' }}>
        <img src={flagPT} width='24px' className='mr-2' />
        PT
      </MenuItem>
    </Select>
  )
}

export default SelectLanguage
