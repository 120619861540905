import React, { useEffect } from 'react'
import {
  Typography,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableCell,
  TableRow,
  TableBody
} from '@mui/material'
import { withTranslation, Trans, useTranslation } from 'react-i18next'

const CookiesPolicy = () => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <div className='container mt-5 pt-5 mb-5 pb-5 terms'>
      <Typography variant='h1' className='mb-5'>
        {t('cookies.title')}
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle1')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('cookies.description1')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle2')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('cookies.description2')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle3')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('cookies.description3')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle4')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('cookies.description4')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle5')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('cookies.description5')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle6')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('cookies.description6')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle7')}
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle8')}
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>
                {t('tableCookies.propertyTitle')}
              </TableCell>
              <TableCell align='left'>
                {t('tableCookies.cookieNameTitle')}
              </TableCell>
              <TableCell align='left'>
                {t('tableCookies.targetTitle')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.termTitle')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_ga`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idLong')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.twoY')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_gid`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idShort')}
              </TableCell>
              <TableCell align='left'>
                {t('tableCookies.twenyFourtH')}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`ajs_anonymous_id`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idMid')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.oneY')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle9')}
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>
                {t('tableCookies.propertyTitle')}
              </TableCell>
              <TableCell align='left'>
                {t('tableCookies.cookieNameTitle')}
              </TableCell>
              <TableCell align='left'>
                {t('tableCookies.targetTitle')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.termTitle')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`__cf_bm`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sesion')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_gat_gtag_UA_127740335_1`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sesion')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_hjAbsoluteSessionInProgress`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sesion')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_hjFirstSeen`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sesion')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_hjIncludedInPageviewSample`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sesion')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_hjIncludedInSessionSample`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sesion')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_hjSession_2452589`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sesion')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_hjSessionUser_2452589`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.oneY')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`amp_e24380`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.oneY')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`crisp-client%2Fsession%2Face03014-b109-45f2-b585-138dc949f60a`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sevenM')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`ps-goals`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sesion')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`b9d98828`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.twenyD')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`psuid`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.eightY')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`tp-clean`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sesion')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`tp-cookie-policy-agree`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.fiveM')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`userLang`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.idGeneral')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.sesion')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle10')}
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>
                {t('tableCookies.propertyTitle')}
              </TableCell>
              <TableCell align='left'>
                {t('tableCookies.cookieNameTitle')}
              </TableCell>
              <TableCell align='left'>
                {t('tableCookies.targetTitle')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.termTitle')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_fbp`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.facebook')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.threeM')}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell component='th' scope='row'>
                {t('tableCookies.property')}
              </TableCell>
              <TableCell align='left'>{`_gcl_au`}</TableCell>
              <TableCell align='left'>
                {t('tableCookies.target.facebook')}
              </TableCell>
              <TableCell align='left'>{t('tableCookies.threeM')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle11')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('cookies.description11')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>

      <Typography variant='h5' className='mb-3 sub'>
        {t('cookies.subtitle12')}
      </Typography>

      <Typography className='mb-3'>
        <Trans
          i18nKey={t('cookies.description12')}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      </Typography>
    </div>
  )
}

export default withTranslation()(CookiesPolicy)
