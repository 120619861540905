import ReactDOM from 'react-dom'
import './scss/App.scss'

// import CssBaseline from '@material-ui/core/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
// import registerServiceWorker from './registerServiceWorker';
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'

import i18n from './i18n'
import { I18nextProvider } from 'react-i18next'

/**
 *
 6D37E2
 6574e2
 46e5a5

 * @type {Theme}
 */
/*
Sentry.init({
  dsn: 'https://f215c1fa2c50474ea32f55d8478a69a5@o245249.ingest.sentry.io/1423307',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})
*/

const theme = createTheme({
  palette: {
    primary: {
      light: '#6574e2',
      main: '##6D37E2',
      dark: '#4552c9',
      contrastText: '#fff'
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#6574e2'
    },
    text: {
      primary: '#39365b',
      secondary: 'rgba(57, 54, 91, 0.70)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    background: {
      paper: '#fff',
      default: '#f9f9fC'
    },
    common: {
      black: '#39365b',
      white: '#fff'
    }
  },
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    color: '#39365b',
    h1: {
      color: '#6D37E2',
      fontSize: '2.8125rem'
    },
    h2: {
      color: '#39365b'
    },
    h3: {
      // color: "##6D37E2",
      fontWeight: 300
    },
    h4: {
      color: '#39365b'
    },
    h5: {
      color: '#39365b'
    },
    h6: {
      color: '#39365b', // rgba(0, 0, 0, 0.87)
      fontWeight: 300
    },
    subtitle1: {
      color: '#39365b'
    },
    body2: {
      color: '#39365b'
    },
    body1: {
      color: '#39365b'
    },
    caption: {
      color: '#39365b'
    },
    button: {
      color: '#39365b',
      border: 'none'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '6px'
      }
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: '15px',
        paddingBottom: '15px'
      }
    },
    MuiInputLabel: {
      marginDense: {
        lineHeight: '1.5 !important'
      }
    },
    MuiFormHelperText: {
      root: {
        lineHeight: '1.2em',
        letterSpacing: 0
      }
    }
  }
})

const container = document.getElementById('root')
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <React.Fragment>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </React.Fragment>
    </BrowserRouter>
  </ThemeProvider>,
  container
)

// registerServiceWorker();
// pwa.register('sw')
