import React from 'react'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'

const InnerMeta = props => {
  const { description, keywords, title, canonical, image, lang } = props
  const prefix = 'Senda | '

  return (
    <Helmet>
      {title ? <title> {prefix + title} </title> : null}
      {canonical ? <link rel='canonical' href={canonical} /> : null}
      {lang ? <meta name='lang' content={lang} /> : null}
      {lang ? <meta httpEquiv='Content-Language' content={lang} /> : null}

      {description ? <meta name='description' content={description} /> : null}
      {keywords ? <meta name='keywords' content={keywords} /> : null}

      {title ? <meta name='twitter:title' content={prefix + title} /> : null}
      {description ? (
        <meta name='twitter:description' content={description} />
      ) : null}
      {canonical ? <meta name='twitter:url' content={canonical} /> : null}
      {image ? <meta name='twitter:image' content={image} /> : null}

      {title ? <meta property='og:title' content={prefix + title} /> : null}
      {description ? (
        <meta property='og:description' content={description} />
      ) : null}
      {canonical ? <meta property='og:url' content={canonical} /> : null}
      {image ? <meta property='og:image' content={image} /> : null}
    </Helmet>
  )
}

export default withTranslation()(InnerMeta)
