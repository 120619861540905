import React from 'react'

import { withTranslation, useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import LowRatesIcon from '../../images/icons/LowRatesIcon.svg'
import EyeIcon from '../../images/icons/EyeIcon.svg'
import MonetizationIcon from '../../images/icons/MonetizationIcon.svg'
import SmileIcon from '../../images/icons/SmileIcon.svg'
import LockIcon from '../../images/icons/LockIcon.svg'
import SendIcon from '../../images/icons/SendIcon.svg'

const Benefits = () => {
  const { t } = useTranslation()

  const benefits = [
    {
      img: LowRatesIcon,
      subtitle: t('LandingSenda.Benefits.0.subtitle'),
      text: t('LandingSenda.Benefits.0.text')
    },

    {
      img: EyeIcon,
      subtitle: t('LandingSenda.Benefits.1.subtitle'),
      text: t('LandingSenda.Benefits.1.text')
    },
    {
      img: MonetizationIcon,
      subtitle: t('LandingSenda.Benefits.2.subtitle'),
      text: t('LandingSenda.Benefits.2.text')
    },
    {
      img: SmileIcon,
      subtitle: t('LandingSenda.Benefits.3.subtitle'),
      text: t('LandingSenda.Benefits.3.text')
    },
    {
      img: LockIcon,
      subtitle: t('LandingSenda.Benefits.4.subtitle'),
      text: t('LandingSenda.Benefits.4.text')
    },

    {
      img: SendIcon,
      subtitle: t('LandingSenda.Benefits.5.subtitle'),
      text: t('LandingSenda.Benefits.5.text')
    }
  ]

  return (
    <section className='benefits' id='benefits'>
      <div className='container custom-container'>
        <div className='benefits-wrapper'>
          <h2>{t('LandingSenda.BenefitsTitle')}</h2>
          <div className='benefits-container'>
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className={`benefit-box ${
                  index === 1 || index === 3 ? 'onDesktop' : ''
                }`}
              >
                <img src={benefit.img} alt={benefit.subtitle} />
                <Typography>{benefit.subtitle}</Typography>
                <Typography>{benefit.text}</Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(Benefits)
