import React, { useEffect, useState } from 'react'
import JsxParser from 'react-jsx-parser'
import { Typography } from '@mui/material'
import InnerMeta from './InnerMeta.jsx'

import { useTranslation } from 'react-i18next'
import { getTerms } from '../fetchs/index.js'
import Menu from './SendaMenu.jsx'
const Terms = () => {
  const { t, i18n } = useTranslation()
  const [textData, settextData] = useState()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTerms()
        const data = await response.json()
        settextData(data.content)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  })
  return (
    <React.Fragment>
      <InnerMeta
        lang={i18n.languages[0]}
        title={t('Footer.legal.terms')}
        description='Description'
      />
      {textData ? (
        <React.Fragment>
          <div className='publicMenuSticky' style={{ display: 'none' }}>
            <Menu />
          </div>
          <JsxParser components={{ Typography }} jsx={textData} />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export default Terms
