import React from 'react'

import { Routes, Route, Navigate } from 'react-router-dom'

import SendaMenu from './components/SendaMenu'
import LandingSenda from './pages/LandingSenda'
import FooterSenda from './components/FooterSenda'

import Terms from './components/TermsV2'
import Privacy from './components/Privacy'
import LSSI from './components/LSSI'
import AmlKyc from './components/AmlKyc'
import CookiesPolicy from './components/CookiesPolicy'

export const Router = props => {
  const genericLanding = props => {
    return (
      <main>
        <SendaMenu />
        <LandingSenda />
        <FooterSenda />
      </main>
    )
  }

  const terms = () => <Terms />

  const termsWeb = () => (
    <main>
      <SendaMenu />
      <Terms />
      <FooterSenda />
    </main>
  )

  const privacy = () => {
    return (
      <main>
        <SendaMenu />
        <Privacy />
        <FooterSenda />
      </main>
    )
  }

  const lssi = () => {
    return (
      <main>
        <SendaMenu />
        <LSSI />
        <FooterSenda />
      </main>
    )
  }

  const amlkyc = () => {
    return (
      <main>
        <SendaMenu />
        <AmlKyc />
        <FooterSenda />
      </main>
    )
  }

  const cookies = () => {
    return (
      <main>
        <SendaMenu />
        <CookiesPolicy />
        <FooterSenda />
      </main>
    )
  }

  return (
    <Routes>
      <Route path='/' Component={() => genericLanding()} />
      <Route exact path='/terms' Component={() => terms()} />
      <Route exact path='/privacy' Component={() => privacy()} />
      <Route exact path='/lssi' Component={() => lssi()} />
      <Route exact path='/aml-kyc' Component={() => amlkyc()} />
      <Route exact path='/cookies' Component={() => cookies()} />
      <Route exact path='/terms-senda' Component={() => termsWeb()} />
      <Route path='/:routeFlag' Component={() => genericLanding()} />

      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  )
}
