import React from 'react'

import { Helmet } from 'react-helmet'
import { Router } from './Router'
import { useTranslation } from 'react-i18next'

import SendaLogo from './images/SendaLogo.png'

function App() {
  const { t } = useTranslation()
  return (
    <div className='App'>
      <React.Fragment>
        <Helmet>
          <meta charSet='utf-8' />
          <meta name='robots' content='index,follow' id='meta-robots' />
          <meta
            name='viewport'
            content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
          />
          <meta name='theme-color' content='#ffffff' />
          <meta httpEquiv='X-UA-Compatible' content='IE=edge,chrome=1' />
          <meta name='msapplication-TileColor' content='#ffffff' />
          <meta
            name='msapplication-TileImage'
            content='%PUBLIC_URL%/mstile-150x150.png'
          />
          <title>Senda</title>
          <meta name='description' content={t('meta.description')} />

          <meta property='og:type' content='website' />
          <meta property='og:image' content={SendaLogo} />
          <meta name='keywords' content={t('meta.keywords')} />

          <meta property='og:title' content={t('meta.og-title')} />

          <meta
            name='trustpilot-one-time-domain-verification-id'
            content='M91R9NhDBGDNCksqJp7C1Xd12dh1MICWMMU9heQ5'
          />
        </Helmet>
        <Router />
      </React.Fragment>
    </div>
  )
}

export default App
