import React from 'react'

import { Typography, Hidden, IconButton } from '@mui/material'

import { withStyles } from '@mui/styles'

import { Link } from 'react-router-dom'

import FacebookIcon from 'mdi-react/FacebookIcon'
import InstagramIcon from 'mdi-react/InstagramIcon'
import { ReactComponent as Tiktok } from '../images/Tiktok.svg'
import Logo from '../images/SendaFooter.svg'

import BRAND_TROPIPAY from '../images/brands/TropiPayBrandFooter.svg'
import PCI_LOGO from '../images/brands/PCIFooter.svg'
import VISA_LOGO from '../images/brands/VisaFooter.svg'
import MASTERCARD_LOGO from '../images/brands/MasterCardFooter.svg'
import SSL_LOGO from '../images/brands/SSLFooter.svg'
import RGPD_LOGO from '../images/brands/RGPDFooter.svg'
import PYME_LOGO from '../images/brands/PYMEINNOVFooter.svg'

import { withTranslation } from 'react-i18next'
import SelectLanguage from './SelectLanguage'

const styles = theme => ({
  underline: {
    '&:before': {
      borderBottom: 'transparent'
    },
    '&:after': {
      borderBottom: '2px solid white'
    }
  },
  whiteColor: {
    color: 'white'
  }
})

const FooterSenda = ({ t, classes }) => {
  const menus = {
    legal: [
      {
        label: 'Footer.legal.terms',
        to: '/terms-senda'
      },
      {
        label: 'Footer.legal.privacy',
        to: '/privacy'
      },
      {
        label: 'Footer.legal.lssi',
        to: '/lssi'
      },
      {
        label: 'Footer.legal.cookies',
        to: '/cookies'
      },
      {
        label: 'Footer.legal.amlKyc',
        to: '/aml-kyc'
      }
    ],
    contact: [
      {
        label: 'Footer.contact.message',
        to: 'mailto:customersupport@sendaapp.com'
      }
    ]
  }

  return (
    <footer className='mt-0 pb-5 pt-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-12 marginB1'>
            <SelectLanguage />
          </div>
          <div className='col-xs-12 col-md-4'>
            <Link className='logo-link' to='/'>
              <img src={Logo} title={t('Footer.logo')} alt={t('Footer.logo')} />
            </Link>
            <Typography
              variant='body2'
              className='text-left subheading my-4 padR4'
            >
              {t('Footer.denomination')}
            </Typography>
            <div className='socials-senda'>
              <IconButton
                title={t('Footer.social.twitter')}
                href='https://www.tiktok.com/@senda.app'
                target='_blank'
                rel='noopener noreferrer'
                style={{ width: '44px', height: '44px', color: '#ffffff' }}
              >
                <Tiktok size={10} />
              </IconButton>
              <IconButton
                title={t('Footer.social.facebook')}
                href='https://www.facebook.com/app.senda/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FacebookIcon size={32} />
              </IconButton>
              <IconButton
                title={t('Footer.social.instagram')}
                href='https://www.instagram.com/senda.app/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <InstagramIcon size={32} />
              </IconButton>
            </div>
          </div>
          <div className='col-xs-12 col-md-8'>
            <div className='row marginB2 justify-content-center'>
              <div className='col-xs-12 col-md-4'>
                <Typography variant='h3' className='text-left subheading my-4'>
                  {t('Footer.contact.name')}
                </Typography>
                <ul className='footerMenu'>
                  {menus.contact.map((item, i) => {
                    return (
                      <li key={i} className='menuItem my-2'>
                        <a
                          href={item.to ? item.to : ''}
                          target={item.to ? '_blank' : ''}
                          rel='noreferrer'
                        >
                          {t(item.label)}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>

              <div className='col-xs-12 col-md-4'>
                <Typography variant='h3' className='text-left subheading my-4'>
                  {t('Footer.legal.name')}
                </Typography>
                <ul className='footerMenu'>
                  {menus.legal.map((item, i) => (
                    <li key={i} className='menuItem my-2'>
                      <Link to={item.to} target={item.target || '_self'}>
                        {t(item.label)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <Hidden smUp>
              <div className='securityImg brands-senda mt-4 mb-4'>
                <div className='col-xs-12 my-3'>
                  <a
                    href='https://www.tropipay.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={BRAND_TROPIPAY} alt='Tropipay Brand' />
                  </a>
                </div>
                <div className='col-xs-12 my-3'>
                  <img src={VISA_LOGO} alt='Visa' />
                </div>
                <div className='col-xs-12 my-3'>
                  <img src={MASTERCARD_LOGO} alt='Master Card' />
                </div>
                <div className='col-xs-12 my-3'>
                  <img src={PCI_LOGO} alt='PCI compliance' />
                </div>
                <div className='col-xs-12 my-3'>
                  <img src={SSL_LOGO} alt='SSL secure' />
                </div>
                <div className='col-xs-12 my-3'>
                  <img src={RGPD_LOGO} alt='RGPD' />
                </div>
                <div className='col-xs-12 my-3'>
                  <img src={PYME_LOGO} alt='PYME INNOVADORA' />
                </div>
              </div>
            </Hidden>
          </div>
          <div className='col-xs-12 col-md-12 marginB1'>
            <Hidden smDown>
              <div className='row security justify-content-center mt-4'>
                <div className='col-xs-12 my-3'>
                  <a
                    href='https://www.tropipay.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={BRAND_TROPIPAY} alt='Tropipay Brand' />
                  </a>

                  <img src={VISA_LOGO} alt='Visa' />
                  <img src={MASTERCARD_LOGO} alt='Master Card' />
                  <img src={PCI_LOGO} alt='PCI compliance' />
                  <img src={SSL_LOGO} alt='SSL secure' />
                  <img src={RGPD_LOGO} alt='RGPD' />
                  <img src={PYME_LOGO} alt='PYME INNOVADORA' />
                </div>
              </div>
            </Hidden>
            <Typography
              variant='body2'
              className='text-center subheading mb-4 mt-4 '
              style={{ maxWidth: '700px', margin: '0 auto' }}
            >
              <span>{t('Footer.licence')}</span>
            </Typography>
            <Typography variant='body1' className='text-center subheading my-4'>
              © Senda {new Date().getFullYear()}
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default withStyles(styles)(withTranslation()(FooterSenda))
