const getPublicHeaders = () => {
  return {
    method: 'GET',
    'Access-Control-Allow-Origin': 'http://localhost:3001',
    headers: { 'Content-Type': 'application/json' }
  }
}

const tppPublicFetch = url => {
  return fetch(process.env.REACT_APP_TPP_WEB_SERVER + url, getPublicHeaders())
}

const tppPublicFetchWithBody = (url, body) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  }
  return fetch(process.env.REACT_APP_TPP_WEB_SERVER + url, options)
}

const getDestinations = async () => {
  return await tppPublicFetch('/api/v2/express_remittance/destinations')
}

const getInfoByCountryAndLang = async (country = 'cu', leng = 'es') => {
  return await tppPublicFetch('/api/v2/landing/' + country + '/' + leng)
}

const getPrices = async body => {
  return await tppPublicFetchWithBody('/api/v2/express_remittance/prices', body)
}

// /api/access/terms-tropisend
const getTerms = async () => {
  return await tppPublicFetch('/api/v2/access/terms-tropisend')
}

export { getDestinations, getPrices, getTerms, getInfoByCountryAndLang }
