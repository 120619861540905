import React, { useState } from 'react'

import { withTranslation, useTranslation } from 'react-i18next'

import { Collapse, List, ListItemButton, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import I18Nhtml from '../../components/i18Nhtml'

const FAQ = props => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(-1)

  const preguntasFrecuentesList = t('LandingSenda.FAQ.List', {
    returnObjects: true
  })

  const handleOpen = item => {
    setOpen(item.index === open ? -1 : item.index)
  }

  return (
    <section className='faq-senda'>
      <div className='container custom-container'>
        <h2>
          <I18Nhtml
            translation='LandingSenda.FAQTitle'
            country={props.activeCountry()}
          />
        </h2>

        {preguntasFrecuentesList.map((item, index) => (
          <div key={index}>
            <ListItemButton onClick={() => handleOpen({ index })}>
              <ListItemText
                className='titleFAQ'
                primary={
                  <I18Nhtml
                    translation={item.question}
                    country={props.activeCountry()}
                  />
                }
              />
              {open === index ? (
                <ExpandLess className='change' />
              ) : (
                <ExpandMore className='change' />
              )}
            </ListItemButton>
            <Collapse in={open === index} unmountOnExit>
              <List component='div' disablePadding>
                <ListItemButton className='col-gray' sx={{ pl: { index } }}>
                  <ListItemText
                    primary={
                      <I18Nhtml
                        translation={item.answer}
                        country={props.activeCountry()}
                      />
                    }
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </div>
        ))}
      </div>
    </section>
  )
}

export default withTranslation()(FAQ)
