import React from 'react'
import { withTranslation } from 'react-i18next'

const i18Nhtml = ({ translation, t, ...anothersProps }) => {
  return (
    <span
      className={anothersProps.className ? anothersProps.className : ''}
      dangerouslySetInnerHTML={{
        __html: t(translation, anothersProps)
      }}
    />
  )
}

export default withTranslation()(i18Nhtml)
